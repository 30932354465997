import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(value?: string | null): any {
    if (!value) {
      return '';
    }
    const [firstName, secondName] = value.split(' ');
    return [firstName?.[0], secondName?.[0] ?? ''].filter((x) => x).join('');
  }
}

@NgModule({
  declarations: [InitialsPipe],
  exports: [InitialsPipe]
})
export class InitialsPipeModule {}
